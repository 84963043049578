<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-input size="small" placeholder="请输入客户编码/客户名称" v-model.trim="listQuery.customerName" style="width: 200px"
          @keyup.enter="getList" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right: 10px" icon="Search">搜索</el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="width: 550px" label-width="90px" size="small" inline="true" class="style_form">
            <el-form-item label="收款日期：">
              <el-date-picker style="width: 150px" v-model="listQuery.beginTime" type="date" placeholder="起始日期"
                clearable value-format="YYYY-MM-DD" />
              &nbsp;-&nbsp;
              <el-date-picker style="width: 150px" v-model="listQuery.endTime" type="date" placeholder="终止日期" clearable
                value-format="YYYY-MM-DD" />
            </el-form-item>
            <el-form-item label="经办人：">
              <select-common placeholder="请选择经办人" v-model:modelValue="listQuery.accountingId" :options="userOptions" />
            </el-form-item>
            <el-form-item label="审核人：">
              <select-common placeholder="请选择审核人" v-model:modelValue="listQuery.auditUserId" :options="userOptions" />
            </el-form-item>
            <el-form-item label="审核状态：">
              <select-common placeholder="请选择审核状态" v-model:modelValue="listQuery.auditStatus"
                :options="statusOptions" />
            </el-form-item>
            <el-form-item label="单据编码：">
              <el-input v-model="listQuery.documentCode" placeholder="请输入单据编码" clearable></el-input>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <!-- <div>
        <el-button size="small" type="primary" icon="Folder">导出</el-button>
      </div> -->
    </div>
    <div :style="{ height: contentStyleObj, paddingTop: '10px' }">
      <vxe-table v-loading="loading" :data="list" :scroll-y="{ enabled: true, gt: 0, mode: 'wheel' }" height="auto" style="width: 100%"
        border stripe auto-resize size="mini" @checkbox-all="handleSelectionChangeAll"
        @checkbox-change="handleSelectionChange" :column-config="{ resizable: true }">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column align="center" field="sortColumn" type="checkbox" width="50">
        </vxe-column>
        <vxe-column field="paymentDate" title="收款日期" width="90"> </vxe-column>
        <vxe-column field="documentCode" title="单据编码" minWidth="90">
          <template #default="scope">
            <span class="clickable_style" @click="chargeDetail(scope.row)">{{
              scope.row.documentCode
            }}</span>
          </template>
        </vxe-column>
        <vxe-column field="inventoryName" title="操作日期" width="90">
          <template #default="scope">
            <span>{{ $parseTime(scope.row.createdAt, "{y}-{m}-{d}") }}</span>
          </template>
        </vxe-column>
        <vxe-column field="customerNum" title="客户编码" width="90">
          <template #default="scope">
            <span>{{ scope.row.customerNum }}</span>
          </template>
        </vxe-column>
        <vxe-column field="customerName" title="客户名称" minWidth="90"> </vxe-column>

        <vxe-column field="receivedAmount" title="金额" minWidth="90"> </vxe-column>
        <vxe-column field="paymentDiscount" title="本次优惠" minWidth="90"> </vxe-column>
        <vxe-column field="accountingName" title="经办人" minWidth="90"> </vxe-column>
        <vxe-column field="cnName" title="录入人" minWidth="90"> </vxe-column>
        <vxe-column field="auditUser" title="审核人" minWidth="90"> </vxe-column>
        <vxe-column field="inventoryName" title="审核状态" minWidth="90">
          <template #default="scope">
            <span>{{ getLabelByValue(scope.row.auditStatus) }}</span>
          </template>
        </vxe-column>
        <vxe-column field="remark" title="审核批语" minWidth="90"> </vxe-column>
        <vxe-column field="paymentExplain" title="收款说明" minWidth="90"> </vxe-column>
        <vxe-column field="opera" title="操作" width="150">
          <template #default="{ row }">
            <el-button type="primary" link :disabled="row.auditStatus !== 5"
              @click="() => showToExamine(row)">审核</el-button>
            <el-button type="primary" link :disabled="![2, 6].includes(row.auditStatus)"
              @click="() => antiAudit(row)">反审核</el-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>

    <div class="page_container">
      <qzf-pagination :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
        @pagination="getList()" />
    </div>
  
    <chargeForm ref="chargeFormRef" :userOptions="userOptions" />
    <ToExamine ref="toExamineRef" @success="getList" />
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { ElMessageBox, ElMessage } from 'element-plus'
import { paymentList, paymentAntiApprove } from "@/api/newCrm";
import chargeForm from "./components/chargeForm.vue";
import { findPeople } from "@/api/base";
import {
  CONTRACT_STATUS,
} from "@/utils/commonData";
import ToExamine from './components/ToExamine.vue'
import { get } from "sortablejs";

const { proxy } = getCurrentInstance();
const list = ref([]);
const contentStyleObj = ref({});
const loading = ref(false);
const listQuery = ref({
  page: 1,
  limit: 20,
});
const userOptions = ref([])
const statusOptions = ref([
  ...[{ label: "全部", value: 0 }],
  ...CONTRACT_STATUS,
]);
const total = ref(0)

const chargeFormRef = ref();
const toExamineRef = ref()

onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(210);
  findPeople({ code: "bookkeeping" }).then((res) => {
    userOptions.value = res.data.data.list.map(item => {
      return {
        label: item.CnName,
        value: item.id,
      }
    });
  });
  getList();
});
const getList = () => {
  paymentList({
    ...listQuery.value,
    auditUserId: listQuery.value.auditUserId || undefined,
    accountingId: listQuery.value.accountingId || undefined,
    auditStatus: listQuery.value.accountingId || undefined,
  }).then((res) => {
    if (res.data.msg == "success") {
      list.value = res.data.data.list;
      total.value = res.data.data.total;
    }
  });
};

function cancel() {
  listQuery.value = {
    page: 1,
    limit: 20,
  }
  getList()
}
const chargeDetail = (row) => {
  chargeFormRef.value.init(row)
}

function showToExamine(row) {
  toExamineRef.value.init(row)
}

const getLabelByValue = (value) => {
  const status = CONTRACT_STATUS.find((item) => item.value === value);
  return status ? status.label : "未知状态";
};

function antiAudit(row) {
  ElMessageBox.confirm('确定反审核?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    paymentAntiApprove({
      type: 3,
      bingingId: [row.id],
      status: 5,
    }).then(res => {
      if (res.data.msg == 'success') {
        ElMessage.success("删除成功")
        getList()
      }
    })
  })
}
</script>
<script>
export default {
  name: "chargeCheck",
};
</script>
<style scoped lang="scss">
.page_container {
  float: right;
  margin-top: 10px;
}
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:deep(.el-checkbox) {
  margin-right: 15px;
}

.style_form {
  .el-input {
    width: 147px;
  }

  .el-select {
    width: 147px;
    margin: 0;
  }
}

.filter-item {
  margin-right: 15px !important;
}

:deep(.el-dropdown-menu__item) {
  display: block;
}
</style>
